.headerHome {
    color: rgb(250, 11, 255, 1);
    text-align: center;
    margin-top: 40px;
    padding: 10px 0;
}

.btnHeader {
    display: flex;
    margin-left: 60%;
}

.logo {
    width: 240px;
}

.palomitas {
    width: 70px;
}