* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
}
.header{
    font-family: "Julee";
    background-color: black !important;
    color: white;
}
.movieListHeader {
    background-color: black!important;
    display: flex;
    color: white;
    size: 10px;
    margin: 30px 0 0 55px;
    

}

.genre::first-letter {
    color: #FA0BFF;

}

.genre {
    align-self: flex-start;
    font-size: 30px;
    margin-right: 70%;

}

.returnButton {
    display: block;
    background-color: black !important;
    cursor: pointer;
    font-size: 20px;
    color: white;
    border: #FA0BFF solid 3px;
    border-radius: 8%;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
}
.search-box {
    background-color: black;
    margin: 30px;
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: black !important;
    color: white;
    padding: 20px 90px;
    justify-content: space-evenly;
}

.movies {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    width: min-content;
    height: 486px;
    align-items: center;
    align-self: stretch;
    margin: 30px;
    font-family: "Arial";
    text-align: center;
    background-color: #57545436 !important;
    cursor: pointer;
}

.title {
    padding: 15px 5px;
    font-weight: bold;
    font-size: 16px;
    vertical-align: center;

}

.poster {
    border-radius: 10px 10px 0 0;
    padding-bottom: 15px;
}

.ratingComp {
    padding-bottom: 30px;
}

#prev,
#next {
    background-color: black;
    align-self: right;
    cursor: pointer;
    font-size: 20px;
    color: white;
    border: #FA0BFF solid 1px;
    border-radius: 8%;
    padding: 10px;
}

#btns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: black;
    color: white;
    justify-content: center;
    padding-bottom: 20px;
}

#page {
    color: #FA0BFF;
    font-weight: bolder;
    font-size: 20px;
    margin: 5px 30px;

}
a{
    text-decoration: none !important;
    color: white !important;
}

